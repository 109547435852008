
.register-image {
    background-color: #333;
    height: 95vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.contact-text{
    color: #F06000;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
    padding-top: 15vh;
    padding-bottom: 5vh;
    background-color:rgba(52, 52, 52, .8);
}
