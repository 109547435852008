
.background-image {
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    height: 100vh;
    
  }

  .learn-more-btn {
    color: 'rgba(255, 99, 71, 1) !important'
  }
  