@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;
  transition: all .3s ease-in-out;
}
header .scrolldown a:hover { color: #11ABB0; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul, #nav-wrap li, #nav-wrap a {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    
}

/* nav-wrap */
#nav-wrap {
  font: 13px 'opensans-bold', sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position:absolute;
  left: 0;
  top: 0;
  
}
.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
  min-height: 48px;
  width: auto;

  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
  
}

/* Links */
ul#nav li a {

/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
   text-decoration: none;
  text-align: left;
  color: #F06000;
   transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #F06000; }


.background-image {
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    height: 100vh;
    
  }

  .learn-more-btn {
    color: 'rgba(255, 99, 71, 1) !important'
  }
  
.socialIcon {
    font-size: 10px;
    color:  "#F06000";
}
.smoothscroll {
    color:  "#F06000";
    background-color: "#333";
}
.nav{
    background-color: "#333";
}


.register-image {
    background-color: #333;
    height: 95vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.contact-text{
    color: #F06000;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding-top: 15vh;
    padding-bottom: 5vh;
    background-color:rgba(52, 52, 52, .8);
}

/*Input text font*/ /*Header text font*/

.footer {
    background-color:rgba(52, 52, 52, 1);
    color: #F06000;
    text-align: center;
    height: 30px;
    padding-top: 5px;
    padding-bottom: 25px;
    margin-bottom: 2px;
    font-family: 'DM Serif Display', serif;
}

.footer2{
    font-size: 18px !important;
    margin-bottom: -1vh;
}

.footer3{
    font-size: 15px !important;
    background-color:rgba(52, 52, 52, 1);
    padding-bottom: 10px;
}
