.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;

  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
header .scrolldown a:hover { color: #11ABB0; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul, #nav-wrap li, #nav-wrap a {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    
}

/* nav-wrap */
#nav-wrap {
  font: 13px 'opensans-bold', sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position:absolute;
  left: 0;
  top: 0;
  
}
.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
  min-height: 48px;
  width: auto;

  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
  
}

/* Links */
ul#nav li a {

/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
   text-decoration: none;
  text-align: left;
  color: #F06000;

   -webkit-transition: color .2s ease-in-out;
   -moz-transition: color .2s ease-in-out;
   -o-transition: color .2s ease-in-out;
   -ms-transition: color .2s ease-in-out;
   transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #F06000; }
